import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./core/Home";
import Signup from "./user/Signup";
import Signin from "./user/Signin";

import AdminRoute from "./auth/helper/AdminRoutes";
import PrivateRoute from "./auth/helper/PrivateRoutes";
import UserDashBoard from "./user/UserDashBoard";
import AdminDashBoard from "./user/AdminDashBoard";
import AddCategory from "./admin/AddCategory";
import ManageCategories from "./admin/ManageCategories";
import AddProduct from "./admin/AddProduct";
import ManageProducts from "./admin/ManageProducts";
import UpdateProduct from "./admin/UpdateProduct";
import Cart from "./core/Cart";

const routing = () => {
  return (
    <BrowserRouter>
    
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/signin" element={<Signin />} />
        <Route path="/cart" element={<Cart />} />
        <Route element={<PrivateRoute />}>
          <Route path="/user/dashboard" element={<UserDashBoard />} />
        </Route>
        <Route element={<AdminRoute />}>
          <Route path="/admin/dashboard" element={<AdminDashBoard />} />
        </Route>
        <Route element={<AdminRoute />}>
          <Route path="/admin/create/category" element={<AddCategory />} />
        </Route>
        <Route element={<AdminRoute />}>
          <Route path="/admin/categories" element={<ManageCategories />} />
        </Route>
        <Route element={<AdminRoute />}>
          <Route path="/admin/create/product" element={<AddProduct />} />
        </Route>
        <Route element={<AdminRoute />}>
          <Route path="/admin/products" element={<ManageProducts />} />
        </Route>
        <Route element={<AdminRoute />}>
          <Route path="/admin/product/update/:productId" element={<UpdateProduct />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default routing;
