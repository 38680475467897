import React,{Fragment} from "react";
import { Link,useNavigate } from "react-router-dom";
import { signout,isAuthenticated } from "../auth/helper";




const Menu = () => {
  const navigate = useNavigate();
  return(
    <div>
      <ul className="nav nav-tabs bg-dark">
        <li className="nav-item">
          <Link className="nav-link" to="/">
            Home
          </Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link" to="/cart">
            Cart
          </Link>
        </li>
        {isAuthenticated() && isAuthenticated().user.role === 0 && (
          <li className="nav-item">
          <Link className="nav-link" to="/user/dashboard">
            Dashboard
          </Link>
        </li>
        )
        }
        {isAuthenticated() && isAuthenticated().user.role === 1 && (
         <li className="nav-item">
         <Link className="nav-link" to="/admin/dashboard">
           A.Dashboard
         </Link>
         </li>
        )
        }
        
        {!isAuthenticated()&&(<Fragment>
        <li className="nav-item">
          <Link className="nav-link" to="/signup">
            Signup
          </Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link" to="/signin">
            Sign in
          </Link>
        </li>
        </Fragment>)}
        {isAuthenticated() &&(
          <li className="nav-item">
          <span className="nav-link text-warning"
          onClick={()=>{
            signout(()=>{
              navigate("/")
            })
          }}
          >Signout</span>
        </li>
        )}
      </ul>
    </div>
  );
};
  
  export default Menu;