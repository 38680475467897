import React from "react";
import { Navigate,Outlet } from "react-router-dom";
import { isAuthenticated } from "./index";

// const AdminRoute = () => {
//   return isAuthenticated()&& isAuthenticated.user.role=== 1 ? <Outlet />:<Navigate to="/signin" replace/>
// };
  

const AdminRoute = () => {
  const auth = isAuthenticated();
  const user = auth && auth.user ? auth.user : null;

  if (!user || user.role !== 1) {
    return <Navigate to="/signin" replace />;
  }

  return <Outlet />;
};




// const AdminRoute = ({ element: element, ...rest }) => {
//   return (
//     <Route
//       {...rest}
//       render={props =>
//         isAuthenticated() && isAuthenticated().user.role === 1 ? (
//           <element {...props} />
//         ) : (
//           <redirect
//             to={{
//               pathname: "/signin",
//               state: { from: props.location }
//             }}
//           />
//         )
//       }
//     />
//   );
// };

export default AdminRoute;
