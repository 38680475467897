
import React from "react"
import Menu from "./Menu";
const Base = ({
  title="SOMEOFAKIND",
  descreption="for those who took the road less travelled by",
  className="bg-dark text-white p-4",
  children
})=>{
  return(
  <div>
    <Menu />
    <div className="container-fluid">
      <div className="jumbotron bg-dark text-white text-center">
        <h2 className="display-4">{title}</h2>
        <p className="lead">{descreption}</p>
        </div>
        <p>Southside</p>
      </div>
      <div className={className}>{children}</div>
      <footer className="footer bg-dark mt-auto py-3">
        <div className="container-fluid bg-success text-white text-center">
          <h4>reachout us </h4>
          <button className="btn btn-warning btn-lg">Contact Us</button>
        </div>
        <div className="container">
           <span className="text-muted">
              <span className="text-danger">Break down the walls</span> Bootcamp
           </span>
         </div>
      </footer>
    </div>
  )
}

export default Base;