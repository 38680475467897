import React, { useState, useEffect } from "react";
import Base from "../core/Base";
import { Link } from "react-router-dom";
// import { isAuthenticated } from "../auth/helper";
import { getCategories } from "./helper/adminapicall";

const ManageCategories = () => {
  const [categories, setCategories] = useState([]);
  const [error, setError] = useState("");


  // const { user, token } = isAuthenticated();

  useEffect(() => {
    preload();
  }, []);

  const preload = () => {
    getCategories()
      .then((data) => {
        if (data?.error) {
          console.log("Error:", data.error);
          setError(data.error);
        } else {
          setCategories(data || []); // Ensure we always set an array
        }
      })
      .catch((err) => {
        console.error("API Error:", err);
        setError("Unable to fetch categories. Please try again later.");
      });
  };

  return (
    <Base title="Welcome admin" description="Manage products here">
      <h2 className="mb-4">All products:</h2>
      <Link className="btn btn-info" to={`/admin/dashboard`}>
        <span className="">Admin Home</span>
      </Link>
      <div className="row">
        <div className="col-12">
          <h2 className="text-center text-white my-3">Total 3 products</h2>
          {categories.map((category, index) => {
            return (
              <h3 className="text-white" key={index}>
                {category.name}
              </h3>
            );
          })}
          <div className="row text-center mb-2 ">
            <div className="col-4">
              <h3 className="text-white text-left">I write code</h3>
            </div>
            <div className="col-4">
              <Link
                className="btn btn-success"
                to={`/admin/product/update/productId`}
              >
                <span className="">Update</span>
              </Link>
            </div>
            <div className="col-4">
              <button onClick={() => {}} className="btn btn-danger">
                Delete
              </button>
            </div>
          </div>
        </div>
      </div>
    </Base>
  );
};

export default ManageCategories;
